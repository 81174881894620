import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IBlend, IMaterial, ISource } from "shared/interfaces";
import { useAppSelector } from "./redux";

const useBatchLatestUsedValues = (): UseQueryResult<{
  materialId: IMaterial["id"];
  sourceId: ISource["id"];
  blendId: IBlend["id"];
  pkgPalletId: string;
}> => {
  const { product } = useAppSelector((state) => state.shift);

  return useQuery(["latestUsedValues"], async () => {
    const api = await getAPI();
    const { data } = await api.get("/batches/latestUsedValues", {
      params: {
        product,
      },
    });
    return {
      materialId: data.materialId,
      sourceId: data.sourceId,
      blendId: data.blendId,
      pkgPalletId: data.pkgPalletId,
    };
  });
};

export default useBatchLatestUsedValues;

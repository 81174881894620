import getAPI from "../services/api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const useShifts = ({
  page = 1,
  pageSize = 100,
  filters = {},
  sort = {},
  enabled = true,
  csv = false,
}: {
  page?: number;
  pageSize?: number;
  filters?: any;
  sort?: any;
  enabled?: boolean;
  csv?: boolean;
}): UseQueryResult<any> => {
  return useQuery(
    ["shifts", page, pageSize, filters, sort, enabled],
    async () => {
      const api = await getAPI();
      const { data } = await api.get("/shifts", {
        params: {
          pagination: { pageSize, page },
          filters,
          sort,
        },
      });
      return data;
    },
    { enabled }
  );
};

export default useShifts;
